@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

@font-face {
    font-family: "Noto";
    // src: local("Noto"), url(./assets/fonts/sinhala/NotoSerif.otf) format("opentype");
    src: local("Poppins"), url(./assets/fonts/Poppins/Poppins-Regular.otf) format("opentype");
}

@font-face {
    font-family: "NotoSans";
    // src: local("NotoSans"), url(./assets/fonts/sinhala/NotoSansSinhala.otf) format("opentype");
    src: local("Poppins"), url(./assets/fonts/Poppins/Poppins-Regular.otf) format("opentype");
}

@font-face {
    font-family: "Guardian";
    // src: local("Guardian"), url(./assets/fonts/guardian-sans-cdnfonts/GuardianSansRegular.otf) format("opentype");
    src: local("Poppins"), url(./assets/fonts/Poppins/Poppins-Regular.otf) format("opentype");
}

@font-face {
    font-family: "Guardian Medium";
    // src: local("Guardian Medium"), url(./assets/fonts/guardian-sans-cdnfonts/GuardianSansMedium.otf) format("opentype");
    src: local("Poppins Medium"), url(./assets/fonts/Poppins/Poppins-Medium.otf) format("opentype");
}

@font-face {
    font-family: "Guardian Bold";
    // src: local("Guardian Bold"), url(./assets/fonts/guardian-sans-cdnfonts/GuardianSansBold.otf) format("opentype");
    src: local("Poppins Bold"), url(./assets/fonts/Poppins/Poppins-Bold.otf) format("opentype");
}

@font-face {
    font-family: "Poppins Extrabold";
    src: local("Poppins Extrabold"), url(./assets/fonts/Poppins/Poppins-ExtraBold.otf) format("opentype");
}

@font-face {
    font-family: "Poppins Bold";
    src: local("Poppins Bold"), url(./assets/fonts/Poppins/Poppins-Bold.otf) format("opentype");
}

@font-face {
    font-family: "Poppins Semibold";
    src: local("Poppins Semibold"), url(./assets/fonts/Poppins/Poppins-SemiBold.otf) format("opentype");
}

@font-face {
    font-family: "Poppins Medium";
    src: local("Poppins Medium"), url(./assets/fonts/Poppins/Poppins-Medium.otf) format("opentype");
}

@font-face {
    font-family: "Poppins";
    src: local("Poppins"), url(./assets/fonts/Poppins/Poppins-Regular.otf) format("opentype");
}

@font-face {
    font-family: "Poppins Thin";
    src: local("Poppins Thin"), url(./assets/fonts/Poppins/Poppins-Thin.otf) format("opentype");
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';


.swiper-slide {
    height: 100% !important;
}

a {
    text-decoration: none !important;
}

* {
    font-family: 'Poppins';
}